.speechBubbleContainer {
  z-index: 2010;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 18vw;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.avatar {
  position: absolute;
  left: 20vw;
  top: 2vw;
}

.avatar img {
  height: 14vw;
}

.speechContent {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 35vw;
  width: 45vw;
  padding: 0;
  height: 18vw;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0vw;
  display: flex;
  align-items: center;
  font-size: 1.6vw;
}

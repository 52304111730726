.screen {
  position: absolute;
  height: 27.5vw;
  width: 41vw;
  left: 31vw;
  top: 8vw;
  color: #000;
}

.window {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  border-width: 0.1vw;
  border-style: solid;
  border-top-color: #fff;
  border-left-color: #fff;
  border-right-color: #000;
  border-bottom-color: #000;
  background-color: #C3C3C3;
  font-family: Arial, Helvetica, sans-serif;
}

.window .header {
  margin-top: 0.1vw;
  margin-left: 0.1vw;
  margin-right: 0.1vw;
  font-size: 1.4vw;
  text-align: left;
  background-color: #00007C;
  color: #fff;
  padding-left: 1vw;
  position: relative;
}

.window .header .closeWindow {
  position: absolute;
  top: 0.2vw;
  right: 0.2vw;
  bottom: 0.2vw;
  width: 2vw;
  background-color: #ccc;
  border-radius: 0;
  border: 0.1vw solid #fff;
  border-bottom-color: #000;
  border-right-color: #000;
  color: #000;
  line-height: 1vw;
  cursor: url(/pointer.png), auto;
}

.window .body {
  padding: 1vw 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vw;
  position: relative;
  width: 50%;
  flex-direction: column;
  font-size: 1.4vw;
}

.window .body p {
  display: block;
}

.window .keypad {
  position: absolute;
  top: 5.8vw;
  right: 0;
  bottom: 0vw;
  width: 50%;
}

.button {
  padding: 0.2vw 0vw;
  width: 3.6vw;
  font-size: 1.4vw;
  position: absolute;
  border-width: 0.1vw;
  border-top-color: #fff;
  border-left-color: #fff;
  color: #000;
  display: block;
  height: 3.2vw;
  background-color: #ccc;
  cursor: url(/pointer.png), auto;
}

.button:active {
  border-color: #bbb;
}

.button1 {
  top: 1vw;
  left: 19%;
}

.button2 {
  top: 1vw;
  left: 40%;
}

.button3 {
  top: 1vw;
  left: 61%;
}

.button4 {
  top: 4.5vw;
  left: 19%;
}

.button5 {
  top: 4.5vw;
  left: 40%;
}

.button6 {
  top: 4.5vw;
  left: 61%;
}

.button7 {
  top: 8vw;
  left: 19%;
}

.button8 {
  top: 8vw;
  left: 40%;
}

.button9 {
  top: 8vw;
  left: 61%;
}

.buttonCLR {
  top: 11.6vw;
  left: 19%;
  font-size: 1.4vw;
  line-height: 2.4vw;
}

.button0 {
  top: 11.6vw;
  left: 40%;
}

.buttonOK {
  top: 11.6vw;
  left: 61%;
}

.shortcut1,
.shortcut2 {
  position: absolute;
  top: 15%;
  left: 5%;
  width: 10vw;
  text-align: center;
  cursor: url(/pointer.png), auto;
}

.shortcut2 {
  top: 50%;
}

.shortcut1 p,
.shortcut2 p {
  margin-top: 1vw;
  font-size: 1.4vw;
  line-height: 1.4vw;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.shortcut1 .icon,
.shortcut2 .icon {
  width: 5vw;
}


.window .bodyLeft {
  padding: 0.5vw 0.5vw;
  top: 5vw;
  left: 1vw;
  height: 16vw;
  position: relative;
  width: 40%;
  text-align: left;
  background: #fff;
  border: 0.1vw solid #000;
  border-bottom-color: #eee;
  border-right-color: #eee;
}

.window .bodyLeft p {
  display: block;
}

.window .bodyRight {
  position: absolute;
  top: 8.9vw;
  right: 0;
  bottom: 0vw;
  width: 50%;
  text-align: center;
}

.window .bodyRight .room {
  padding: 1.4vw 0.2vw;
  width: 90%;
  border-color: #666;
  border-bottom-color: #fff;
  border-right-color: #fff;
  border-style: solid;
  border-width: 0.1vw;
  margin-bottom: 1vw;
  font-size: 1.4vw;
  /* box-shadow: 0.1vw 0.1vw 0 0 #fff; */
}

.window .bodyRight .room.roomActive {
  background-color: rgb(199, 199, 90);
}

.window .dragNotification {
  position: absolute;
  top: 3.6vw;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.6;
  font-size: 1.4vw;
}

.file {
  width: 16vw;
  cursor: url(/pointer.png), auto;
  font-size: 1.4vw;
  font-family: Arial, Helvetica, sans-serif;
}

.fileDragging {
  background-color: #00007C;
  color: #fff;
  padding-left: 0.5vw;
}
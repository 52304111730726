.errorPage {
  color: #fff;
  text-align: center;
}

.errorPage h1 {
  color: #fff;
}

.button {
  position: static;
}
.parrotContainer {
  /* border: 1px solid red; */
  width: 34vw;
  height: 35vw;
  position: absolute;
  top: 0vw;
  left: 28vw;
}

.body {
  position: absolute;
  width: 14.8vw;
  top: 12.1vw;
  left: 1.7vw;
  pointer-events: none;
  z-index: 2;
}

.head {
  position: absolute;
  width: 13.9vw;
  top: 7.9vw;
  left: 2.8vw;
  pointer-events: none;
  z-index: 2;
  transform-origin: bottom left;
  animation-name: head;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes head {
  0%   { transform: translate(0, 0) rotate(0); }
  43%   { transform: translate(0, 0) rotate(0); }
  50%  { transform: translate(0, 0) rotate(3deg) }
  56%   { transform: translate(0, 0) rotate(0); }
  100% { transform: translate(0, 0) rotate(0); }
}

.wing1 {
  position: absolute;
  width: 5.1vw;
  top: 16vw;
  left: 0.3vw;
  pointer-events: none;
  transform-origin: top left;
  animation-name: wing;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.wing2 {
  position: absolute;
  width: 4.9vw;
  top: 16.2vw;
  left: 12.8vw;
  pointer-events: none;
  transform-origin: top left;
  animation-name: wing;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes wing {
  0%   { transform: translate(0, 0); }
  50%  { transform: translate(0, -0.5vw); }
  100% { transform: translate(0, 0); }
}

.tail {
  position: absolute;
  width: 8vw;
  top: 32.7vw;
  left: 10.4vw;
  pointer-events: none;
  transform-origin: top left;
  animation-name: tail;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes tail {
  0%   { transform: translate(0, 0) rotate(0); }
  43%   { transform: translate(0, 0) rotate(0); }
  50%  { transform: translate(0, 0) rotate(3deg) }
  56%   { transform: translate(0, 0) rotate(0); }
  100% { transform: translate(0, 0) rotate(0); }
}

.speechBubbleContainer {
  position: absolute;
  width: 50vw;
  left: 23vw;
  top: 17vw;
  transform-origin: -9vw -1vw;
  transform: scale(0);
  transition: all 500ms ease-in;
  z-index: 4;
}

.speechBubbleContainerVisible {
  transform: scale(1);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.speechBubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 40vw;
}

.speechBubbleText {
  position: absolute;
  top: 5vw;
  left: 10vw;
  width: 27vw;
  height: 12vw;
  font-size: 1.6vw;
  display: flex;
  /* justify-content: center; align horizontal */
  align-items: center;
}

.answers {
  position: absolute;
  top: 44vw;
  left: 10vw;
  height: 8vw;
  width: 80vw;
  text-align: center;
  transform-origin: top;
  transform: scale(0);
  transition: all 500ms ease-in;
}

.answersVisible {
  transform: scale(1);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.answerButton {
  display: inline-block;
  position: relative;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.bars,
.stick {
  position: absolute;
  z-index: 3;
  width: 100vw;
  left: 0;
  top: 0;
  pointer-events: none;
}

.stick {
  z-index: 1;
}
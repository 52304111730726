.shape0,
.shape1,
.shape2,
.shape3,
.shape4,
.shape5,
.shape6,
.shape7 {
  position: absolute;
  width: 3.4vw;
}

.shape0 {
  top: 22.5vw;
  left: 37.25vw;
  transform:rotate(180deg);
}

.shape1 {
  top: 22.5vw;
  left: 44.65vw;
  transform:rotate(180deg);
}

.shape2 {
  top: 22.5vw;
  left: 52.15vw;
  transform:rotate(180deg);
}

.shape3 {
  top: 22.5vw;
  left: 59.65vw;
  transform:rotate(180deg);
}

.shape4 {
  top: 25.8vw;
  left: 37.25vw;
}

.shape5 {
  top: 25.8vw;
  left: 44.65vw;
}

.shape6 {
  top: 25.8vw;
  left: 52.15vw;
}

.shape7 {
  top: 25.8vw;
  left: 59.65vw;
}

.solution {
  position: absolute;
  color: #fff;
  left: 3vw;
  top: 48vw;
  font-size: 1.6vw;
}

.solutionWhite {
  height: 2.5vw;
}
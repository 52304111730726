.linkButton {
  position: absolute;
  border: none;
  color: #fff;
  border-radius: 0.5vw;
  height: auto;
  padding: 1vw 3vw;
  font-size: 2vw;
  background-color: transparent;
  cursor: url(/pointer.png), auto;
}

.linkButtonSmall {
  padding: 0.5vw 1vw;
  font-size: 1.6vw;
}

.linkButton:hover,
.linkButton:focus,
.linkButton:active {
  background-color: transparent;
  border-color: #eee;
  color: #eee;
  outline: none;
}

.linkButton:hover,
.linkButton:active {
  background-color: transparent;
  color: #DE5196;
  outline: none;
}

.noBorder {
  border: 0;
}
.loadingPage {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #fff;
}

.barWrapper {
  top: 27vw;
  position: absolute;
  width: 100vw;
  height: 0.4vw;
  left: 0vw;
  /* border: 0.1vw solid #fff; */
}

.bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}
.bars {
  position: absolute;
  z-index: 2;
  width: 100vw;
  left: 0;
  top: 0;
  cursor: url(/pointer.png), auto;
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.body {
  position: absolute;
  width: 21.2vw;
  top: 16.8vw;
  left: 41.6vw;
  animation-name: breathing;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.head {
  position: absolute;
  width: 11.5vw;
  top: 11vw;
  left: 49.6vw;
}

.tail {
  position: absolute;
  width: 20.9vw;
  top: 37.3vw;
  left: 55vw;
  transform-origin: bottom left;
  animation-name: tail;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.showArm.body {
  left: 31.6vw;
}

.showArm.head {
  left: 39.6vw;
}

.showArm.tail {
  left: 45vw;
}

@keyframes arm2 {
  0%  { transform: translate(0, 0); }
  14% { transform: translate(-9vw, 0); }
  18% { transform: translate(-9vw, 0); }
  32% { transform: translate(-5vw, -4vw); }
  36% { transform: translate(-5vw, -4vw); }
  50% { transform: translate(0vw, 6vw); }
  54% { transform: translate(0vw, 6vw); }
  68% { transform: translate(-9vw, 11vw); }
  82% { transform: translate(-9vw, 11vw); }
  96%  { transform: translate(0, 0); }
}

.arm2 {
  position: absolute;
  width: 10.5vw;
  top: 17vw;
  left: 52vw;
  animation-name: arm2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes arm1 {
  0%  { transform: translate(0, 0) rotate(-35deg); }
  14% { transform: translate(-9vw, 0) rotate(2deg); }
  18% { transform: translate(-9vw, 0) rotate(2deg); }
  32% { transform: translate(-5vw, -4vw) rotate(-35deg); }
  36% { transform: translate(-5vw, -4vw) rotate(-35deg); }
  50% { transform: translate(-1vw, 7.6vw) rotate(-6deg) }
  54% { transform: translate(-1vw, 7.6vw) rotate(-6deg) }
  68% { transform: translate(-9vw, 11vw) rotate(25deg); }
  82% { transform: translate(-9vw, 11vw) rotate(25deg); }
  96% { transform: translate(0, 0) rotate(-35deg); }
}

.arm1 {
  position: absolute;
  width: 7vw;
  top: 13.3vw;
  left: 50vw;
  transform: translate(0, 0) rotate(-35deg);
  transform-origin: bottom right;
  animation-name: arm1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes tail {
  0%   { transform: translate(0, 0) rotate(0); }
  47%   { transform: translate(0, 0) rotate(0); }
  50%  { transform: translate(0, 0) rotate(-3deg) }
  53%   { transform: translate(0, 0) rotate(0); }
  100% { transform: translate(0, 0) rotate(0); }
}

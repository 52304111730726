.drag {
  position: absolute;
  top: 16vw;
  left: 68vw;
  width: 17vw;
}

.bottle {
  width: 8vw;
  transition: transform ease 0.3s;
}

.bottleActive {
  transform: scale(1.1);
}

.bottleDrop {
  position: absolute;
  width: 9vw;
}

.bottle0 {
  top: 24.9vw;
  left: 27.7vw;
}

.bottle2 {
  top: 11.6vw;
  left: 33.8vw;
}

.bottle4 {
  top: 10.1vw;
  left: 45.7vw;
}

.bottle6 {
  top: 11.6vw;
  left: 61.6vw;
}

.bottle8 {
  top: 23.9vw;
  left: 52.4vw;
}

.bottle9 {
  top: 25.2vw;
  left: 39.3vw;
}

.paper {
  height: 15.53vw;
  width: 5.5vw;
  background-size: cover;
}

.paperDrag {
  left: 84vw;
  top: 13vw;
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.reset {
  position: absolute;
  top: 60%;
  left: 60%;
  padding: 0px;
  width: 30%;
  text-align: center;
}
.tailWrapper {
  position: absolute;
  height: 10vw;
  width: 21vw;
  top: 5.4vw;
  left: 49.8vw;
  animation-name: tailWiggle;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  transform-origin: bottom left;
}

.tail1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5.7vw;
}

.tail2 {
  position: absolute;
  top: 6.6vw;
  left: 4.4vw;
  width: 7.8vw;
  animation-name: tailWiggle2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  transform-origin: bottom left;
}

@keyframes tailWiggle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tailWiggle2 {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
}

@keyframes headWiggle {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-3deg);
  }
}

@keyframes tongue {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1
  }
  48% {
    opacity: 1
  }
  48.1% {
    opacity: 0;
  }
}

.headWrapper {
  position: absolute;
  height: 17vw;
  width: 42.8vw;
  top: 28.5vw;
  left: 18vw;
  animation-name: headWiggle;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  transform-origin: top right;
}

.neck {
  position: absolute;
  right: 0;
  top: 0;
  width: 16vw;
}

.head {
  position: absolute;
  right: 10.3vw;
  top: 1.1vw;
  width: 10vw;
  z-index: 1;
}

.tongue {
  position: absolute;
  right: 19.9vw;
  top: 6.4vw;
  width: 1.5vw;
  transform: rotate(54deg);
  z-index: 2;
  opacity: 0;
  animation-name: tongue;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

.snakeClickContainer {
  position: absolute;
  top: 7vw;
  left: 22vw;
  height: 37vw;
  width: 64vw;
}
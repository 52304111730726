.thermoBg {
  position: absolute;
  top: 17vw;
  left: 21vw;
  width: 12vw;
  z-index: 3;
}

.thermo {
  position: absolute;
  top: 17vw;
  left: 21vw;
  width: 12vw;
  z-index: 5;
}

.redLine {
  position: absolute;
  top: 27vw;
  left: 20.8vw;
  width: 6vw;
  transition: all 2s ease-in-out;
  z-index: 3;
}

.tankInside {
  position: absolute;
  top: 16vw;
  left: 27vw;
  height: 20vw;
  width: 53vw;
}

.cloudy {
  position: absolute;
  top: 23.7vw;
  left: 25.4vw;
  width: 51vw;
  z-index: 1;
  animation-name: cloudy;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.doNotTap {
  position: absolute;
  width: 44vw;
  top: 33.9vw;
  left: 35.5vw;
  z-index: 2;
  pointer-events: none;
}

.tapOnGlassContainer {
  width: 62vw;
  height: 30vw;
  left: 20vw;
  top: 10vw;
  position: absolute;
  z-index: 7;
  cursor: url(/pointer.png), auto;
}

.fishLeft,
.fishRight {
  z-index: 1;
  position: absolute;
  width: 6vw;
  left: 80%;
  top: 50%;
  transition: all 1.5s ease-in-out;
  animation-name: bodyFloat;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.fishBody {
  position: absolute;
  width: 6vw;
  top: 0;
  left: 0;
}

.fishTailLeft,
.fishTailRight {
  position: absolute;
  width: 2vw;
  top: 1.17vw;
  right: -1.5vw;
  transform-origin: left center;
  animation-name: tailSwim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.fishTailRight {
  right: auto;
  left: -1.5vw;
  transform-origin: right center;
}

@keyframes tailSwim {
  0%   { transform: rotateY(0deg); }
  50%  { transform: rotateY(40deg); }
  100% { transform: rotateY(0deg); }
}

@keyframes bodyFloat {
  0%   { transform: translate(0, 0); }
  10%  { transform: translate(10%, 0vw); }
  30%  { transform: translate(5%, 0.15vw); }
  70%  { transform: translate(0%, -0.15vw); }
  100% { transform: translate(0, 0); }
}

@keyframes bodyFloat2 {
  0%   { transform: translate(0, 0); }
  10%  { transform: translate(-10%, 0vw); }
  30%  { transform: translate(-5%, -0.15vw); }
  70%  { transform: translate(0%, 0.15vw); }
  100% { transform: translate(0, 0); }
}

.love {
  position: absolute;
  width: 1vw;
  opacity: 0;
  z-index: 10;
  animation-name: love;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 3s;
}

@keyframes love {
  0%   { transform: translate(0, 0); opacity: 0; }
  33%  { transform: translate(-20%, -1vw); opacity: 1 }
  66%  { transform: translate(20%, -2vw); opacity: 1 }
  100% { transform: translate(0, -3vw); opacity: 0; }
}

@keyframes cloudy {
  0%   { transform: translate(0, 0); }
  30%  { transform: translate(0, -0.15vw); }
  60%  { transform: translate(0, 0.15vw) scale(0.98); }
  100% { transform: translate(0, 0); }
}
.scaleLeft,
.scaleRight {
  position: absolute;
  top: 10vw;
  left: 50.2vw;
  width: 38.8vw;
  z-index: 1;
}

.scaleRight {
  left: 8.4vw;
}

.dragTutorialLeft,
.dragTutorialRight {
  position: absolute;
  top: 20vw;
  left: 37vw;
  width: 13vw;
  z-index: 4;
  pointer-events: none;
  transition: opacity 500ms ease-in-out;
}

.dragTutorialRight {
  left: 48vw;
}

.scaleDropLeft,
.scaleDropRight {
  position: absolute;
  top: 5vw;
  left: 47vw;
  width: 42vw;
  height: 45vw;
  /* background-color: rgba(255, 0, 0, 0.3); */
}

.scaleDropRight {
  left: 12.7vw;
}

.placeholderDropLeft,
.placeholderDropRight {
  position: absolute;
  top: 0vw;
  left: 7vw;
  width: 46vw;
  height: 48vw;
  /* background-color: rgba(255, 0, 0, 0.2); */
}

.placeholderDropRight {
  left: 45vw;
}

.placeholder {
  position: absolute;
  width: 26vw;
  top: 35vw;
  left: 5vw;
}

.placeholderRight {
  position: absolute;
  left: 64vw;
}

.weight {
  background-size: cover;
}

.weight0 {
  height: 8vw;
  width: 3.7vw;
}

.weight1 {
  height: 8vw;
  width: 3.7vw;
}

.weight2 {
  height: 10.5vw;
  width: 4.6vw;
}

.weight3 {
  height: 12vw;
  width: 5.7vw;
}

.weight4 {
  height: 7vw;
  width: 3.2vw;
}

.weight5 {
  height: 8vw;
  width: 3.6vw;
}

.weight6 {
  height: 8vw;
  width: 3.6vw;
}

.weight7 {
  height: 14vw;
  width: 6.6vw;
}

.weightDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.weightDrag0 {
  top: 32vw;
  left: 29.4vw;
}

.weightDrag1 {
  top: 31vw;
  left: 41.5vw;
}

.weightDrag2 {
  top: 29.3vw;
  left: 34.7vw;
}

.weightDrag3 {
  top: 27.5vw;
  left: 46.3vw;
}

.weightDrag4 {
  top: 32.5vw;
  left: 46vw;
}

.weightDrag5 {
  top: 31.2vw;
  left: 64.6vw;
}

.weightDrag6 {
  top: 31.7vw;
  left: 59.2vw;
}

.weightDrag7 {
  top: 25.5vw;
  left: 51vw;
}

.weightOnScale.weightDrag0 {
  top: 14.6vw;
  left: 52.4vw;
}

.weightOnScale.weightDrag1 {
  top: 14.6vw;
  left: 61.6vw;
}

.weightOnScale.weightDrag2 {
  top: 12vw;
  left: 56.7vw;
}

.weightOnScale.weightDrag3 {
  top: 10.5vw;
  left: 65.7vw;
}

.weightOnScale.weightDrag4 {
  top: 15.4vw;
  left: 27.1vw;
}

.weightOnScale.weightDrag5 {
  top: 14.5vw;
  left: 31.1vw;
}

.weightOnScale.weightDrag6 {
  top: 14.5vw;
  left: 42.2vw;
}

.weightOnScale.weightDrag7 {
  top: 8.5vw;
  left: 35.3vw;
}

.screen {
  position: absolute;
  top: 7.7vw;
  left: 53.8vw;
  width: 27vw;
  height: 17vw;
  z-index: 1;
  overflow: hidden;
}

.screenOldDog {
  position: absolute;
  top: 7.7vw;
  left: 16.4vw;
  width: 27vw;
  height: 17vw;
  z-index: 1;
  overflow: hidden;
}

.digitalScale {
  position: absolute;
  width: 23vw;
  top: 10vw;
  left: 2vw;
  pointer-events: none;
}

.marker {
  width: 4.4vw;
  position: absolute;
  top: 6.7vw;
  left: 11.35vw;
  transition: transform 0.5s ease-in-out;
  /* left: 1.15vw; max left */
}

.statusText {
  position: absolute;
  width: 23.2vw;
  top: 2vw;
  left: 2vw;
}

.screen.oldDog {
  left: 16.3vw;
}

.screen .weightStatus,
.screen .overallStatus,
.screen .overallStatusSummary {
  color: #3f3;
  position: absolute;
  font-size: 1.2vw;
  left: 3.2vw;
  width: 25.8vw;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}

.screen .weightStatus {
  top: 4.9vw;
}

.screen .overallStatus {
  top: 13vw;
}

.screen .overallStatusSummary {
  top: 15vw;
  font-size: 1.6vw;
}

.screen .screenWeight {
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #3f3;
  font-size: 5vw;
  position: absolute;
  top: 6.2vw;
  left: 3.2vw;
  display: inline-block;
  width: 25.8vw;
  text-align: center;
}

.screen.screenErrored .weightStatus,
.screen.screenErrored .overallStatus,
.screen.screenErrored .overallStatusSummary,
.screen.screenErrored .screenWeight {
  color: #f33;
}

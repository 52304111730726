.dropzone {
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: 94vw;
  height: 52vw;
  z-index: 1;
  /* background-color: rgba(255, 0, 0, 0.1); */
}

.key {
  background-size: cover;
  background-repeat: no-repeat;
  width: 9.6vw;
  height: 23vw;
}

.keyDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
  top: 16.25vw;
  left: 64.6vw;
}

.keyDrag:hover .rotate {
  opacity: 1;
}

.rotate {
  opacity: 0.3;
  position: absolute;
  top: 2.9vw;
  left: 3vw;
  width: 4vw;
  z-index: 1;
}

.rotateWrapper {
  transition: ease-in-out 500ms;
  transform-origin: 4.9vw 4.8vw;
}

.dragTutorial {
  position: absolute;
  top: 7.1vw;
  left: -17.4vw;
  width: 20vw;
  z-index: 4;
  pointer-events: none;
  transition: opacity 500ms ease-in-out;
}
/* .characters {

} */


.gameBoardCharacters {
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}

.gameBoardCharacters.startingTheGame {
  transition: opacity 3s linear;
  opacity: 0;
}

.chooseCharacterH {
  position: absolute;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  left: 24vw;
  right: 24vw;
  top: 5vw;
  z-index: 5;
  font-weight: bold;
  font-size: 3vw;
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  border-radius: 1vw;
}

.chooseCharacterH.characterSelected {
  opacity: 0;
}

.characters .oldDogWrapper,
.characters .allyWrapper {
  position: absolute;
  top: 0;
  left: -12vw;
  width: 72.2vw;
  height: 100%;
  transition: all 200ms ease-in-out;
  cursor: url(/pointer.png), auto;
  opacity: 0.8;
}

.characters .allyWrapper {
  right: -28vw;
  left: auto;
  width: 76.5vw;
}

.characters .oldDogWrapper.characterSelected .oldDog,
.characters .allyWrapper.characterSelected .ally,
.characters .oldDogWrapper.characterSelected,
.characters .allyWrapper.characterSelected {
  opacity: 1;
}

.characters .oldDogWrapper.otherCharacterSelected,
.characters .allyWrapper.otherCharacterSelected {
  opacity: 0;
}

/* .characters .oldDogWrapper:hover {
  /* left: 0vw; */
/* }  */

.characters .oldDogWrapper .oldDog {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.6;
  transition: opacity 200ms ease-in-out;
}

.characters .oldDogWrapper:hover .oldDog,
.characters .allyWrapper:hover .ally,
.characters .oldDogWrapper:hover,
.characters .allyWrapper:hover {
  opacity: 1;
}

.characters .oldDogWrapper:hover .oldDogName,
.characters .allyWrapper:hover .allyName {
  opacity: 1;
}

.characters .oldDogWrapper .oldDogBackground {
  position: absolute;
  left: -12.5vw;
  top: 0;
  height: 100%;
  z-index: 1;
}

.characters .oldDogWrapper .oldDogBeaker {
  position: absolute;
  left: 28.7vw;
  top: 0;
  height: 100%;
  z-index: 3;
}

.characters .oldDogName,
.characters .allyName {
  position: absolute;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  left: 27vw;
  bottom: 1vw;
  z-index: 5;
  font-weight: bold;
  font-size: 2.5vw;
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  border-radius: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.characters .allyName {
  left: auto;
  right: 40vw;
}

.characters .allyWrapper .ally {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 2;
  opacity: 0.6;
  transition: all 200ms ease-in-out;
}

.characters .allyWrapper .allyBackground {
  position: absolute;
  right: -1vw;
  top: 0;
  height: 100%;
  z-index: 1;
}

.characters .allyWrapper .allyBeaker {
  position: absolute;
  right: 49.2vw;
  top: 0;
  height: 100%;
  z-index: 3;
}

.characters .divider {
  position: absolute;
  height: 100%;
  top: 0;
  left: 36vw;
  z-index: 4;
  pointer-events: none;
}

.steps {
  position: absolute;
  right: 0;
  padding-right: 1vw;
  top: 0;
  width: 44vw;
  height: 100%;
  z-index: 6;
  opacity: 0;
  font-size: 1.4vw;
  letter-spacing: 0.1vw;
  transition: opacity 1s linear;
  pointer-events: none;
}

.allySelected .steps {
  right: auto;
  left: 0;
  padding-left: 1vw;
  padding-right: 0;
}

.oldDogSelected .steps.stepVisible,
.allySelected .steps.stepVisible {
  opacity: 1;
  pointer-events: all;
}

.steps .twoComputers {
  position: absolute;
  width: 30vw;
  margin-left: -15vw;
  left: 50%;
  top: 10vw;
}

.steps .notes {
  position: absolute;
  width: 12vw;
  margin-left: -6vw;
  left: 50%;
  top: 10vw;
}

.steps.stepSound .speaker {
  position: absolute;
  width: 10vw;
  margin-left: -5vw;
  left: 50%;
  top: 10vw;
  cursor: url(/pointer.png), auto;
}

.steps.stepSound .speakerContinue {
  width: 20vw;
  margin-left: -10vw;
  left: 50%;
  top: 38vw;
}

.steps .description {
  position: absolute;
  top: 28vw;
  left: 1vw;
  right: 1vw;
  color: #fff;
  letter-spacing: 0.1vw;
  text-align: center;
}

.steps.stepName .description {
  top: 20vw;
}

.steps.stepName .nameInput {
  width: 30vw;
  background-color: transparent;
  text-align: center;
  border: 0.2vw solid #fff;
  font-size: 2vw;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1vw;
}

.steps.stepName .nameInput:active,
.steps.stepName .nameInput:focus {
  outline: none;
}

.steps.stepName .nameContinue {
  width: 20vw;
  margin-left: -10vw;
  left: 50%;
  top: 30vw;
}

.steps.stepNotes .continue {
  width: 20vw;
  margin-left: -10vw;
  left: 50%;
  top: 40vw;
}

.steps.stepDevices .continue {
  width: 20vw;
  margin-left: -10vw;
  left: 50%;
  top: 44vw;
}

.steps.stepTeam .description span {
  font-weight: bold;
}

.steps.stepTeam .twoComputers {
  top: 3vw;
}

.steps.stepTeam .description {
  top: 6vw;
}

.steps.stepTeam .players h3 {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.steps.stepTeam .players {
  position: absolute;
  left: 1vw;
  right: 1vw;
  top: 18vw;
  color: #fff;
  text-align: center;
}

.steps.stepTeam .players p {
  margin-bottom: 0;
}

.switch {
  color: #DE5196;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.steps.stepTeam .startGame {
  width: 20vw;
  margin-left: -10vw;
  bottom: 5vw;
  left: 50%;
}

.steps.stepTeam .startGame:disabled {
  background-color: transparent;
  color: #666;
  border-color: #666;
}

.steps.stepTeam .disabledDescription {
  color: #fff;
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  right: 0;
  text-align: center;
}
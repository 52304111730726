.arrow {
  background-size: cover;
  height: 3.8vw;
  width: 3.8vw;
  /* border-radius: 1vw;
  background-color: #C0A39A;
  box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.5); */
}

.arrowDrag {
  position: absolute;
  z-index: 2;
  top: 13.3vw;
  left: 67.5vw;
  cursor: url('/pointer.png'), auto;
}

.arrowDrag1 {
  top: 17.7vw;
  left: 67.5vw;
}

.arrowDrag2 {
  top: 13.3vw;
  left: 72vw;
}

.arrowDrag3 {
  top: 17.7vw;
  left: 72vw;
}

.arrowDragBlocked {
  cursor: auto;
}

.dropzone {
  position: absolute;
  width: 3.9vw;
  height: 3.9vw;
  /* background-color: rgba(255, 0, 50, 0.3); */
}

.dropzoneBase {
  position: absolute;
  width: 14.9vw;
  height: 27.9vw;
  top: 7vw;
  left: 64vw;
  /* background-color: rgba(0, 255, 50, 0.1); */
}

.mouseLefty,
.mouseRighty {
  position: absolute;
  width: 3.9vw;
  height: 3.9vw;
  transition: all 300ms;
  z-index: 3;
}

.mouseLefty.mouseDead,
.mouseRighty.mouseDead {
  transition: all 3000ms;
}

.playButton {
  top: 38.1vw;
  left: 36vw;
  width: 11vw;
}

.resetButton {
  top: 38.1vw;
  left: 50vw;
  width: 11vw;
}
.modalBody {
  height: 100%;
  width: 100vw;
  position: relative;
  margin: 0 auto;
}

.canvas {
  position: absolute;
  left: 0;
  top: -30px;
  bottom: 0;
}

.canvas:hover {
  cursor: url('/pen.png'), auto;
}

.description {
  text-align: center;
  padding: 5px 100px;
}

.clear {
  float: left;
}

.close {
  float: right;
}

.image {
  display: block;
  width: 100vw;
  margin: 0 auto;
}

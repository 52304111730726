.tapCold {
  position: absolute;
  top: 9.5vw;
  left: 23.6vw;
  width: 13vw;
  transform-origin: center;
  transition: all 1s ease-in-out;
}

.tapHot {
  position: absolute;
  top: 9.5vw;
  left: 46vw;
  width: 13vw;
  transform-origin: center;
  transition: all 1s ease-in-out;
}

.tapColdLeft {
  position: absolute;
  left: 20.6vw;
  top: 6.5vw;
  width: 10vw;
  z-index: 1;
}

.tapColdRight {
  position: absolute;
  left: 29.6vw;
  top: 6.5vw;
  width: 10vw;
  z-index: 1;
}

.tapHotLeft {
  position: absolute;
  left: 43.5vw;
  top: 7vw;
  width: 10vw;
  z-index: 1;
}

.tapHotRight {
  position: absolute;
  left: 51.5vw;
  top: 7vw;
  width: 10vw;
  z-index: 1;
}
.lockImageContainer {
  text-align: center;
}

.lockImage {
  width: 100%;
}

.numberContainer {
  position: absolute;
  top: 25vw;
  left: 0;
  right: 0;
  height: 15vw;
}

.numberAndControlsContainer {
  position: absolute;
}

.number {
  height: 7vw;
  width: 5vw;
  position: absolute;
  overflow: hidden;
  top: 4.5vw;
  background-size: cover;
  border: 0.3vw solid #000;
  /*transition: background 400ms cubic-bezier(0.25, 0.1, 0.26, 1.61) 0s;*/
}

.number img {
  width: 4.5vw;
  /*z-index: 1;*/
  transition: transform 400ms cubic-bezier(0.25, 0.1, 0.26, 1.61) 0s;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowUp,
.arrowDown {
  height: 4.5vw;
  width: 5vw;
  position: absolute;
  top: 0;
  background-size: cover;
  font-size: 7.5vw;
  line-height: 11vw;
  text-align: center;
  font-family: 'Indie Flower';
  cursor: url('/pointer.png'), auto;
  color: rgba(255, 255, 255, 0.6);
}

.arrowDown {
  top: 11.6vw;
  transform: rotate(180deg);
}

.arrowUp:hover,
.arrowDown:hover {
  color: rgba(255, 255, 255, 1);
}

.arrowUp:active,
.arrowDown:active {
  color: rgba(255, 255, 0, 1);
}

.buttonContainer {
  text-align: center;
}

.submitButton {
  border: 0.3vw solid #eee;
  color: #eee;
  border-radius: 0.5vw;
  height: auto;
  padding: 1vw 3vw;
  font-size: 2.5vw;
  background-color: transparent;
  border-width: 0.4vw 0.4vw 0.6vw 0.6vw;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
}

.submitButton:hover,
.submitButton:focus,
.submitButton:active {
  background-color: transparent;
  border-color: #eee;
  color: #eee;
}

.submitButton:hover,
.submitButton:active {
  background-color: transparent;
  border-color: rgba(255, 255, 0, 1);
  color: rgba(255, 255, 0, 1);
}

.solution {
  position: absolute;
  color: #fff;
  left: 3vw;
  top: 48vw;
  font-size: 1.6vw;
}
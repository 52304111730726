.handle {
  position: absolute;
  left: 48.2vw;
  top: 16.8vw;
  width: 22vw;
  cursor: url(/pointer.png), auto;
  z-index: 2;
  transform-origin: 18vw 2.5vw;
}

.handlemoving {
  animation-duration: 2800ms;
  animation-iteration-count: 10;
  animation-timing-function: ease-in-out;
  animation-name: tryHandle;
}

.handleunlock {
  transition: transform 400ms ease-in-out;
  transform: rotate(-50deg);
}

@keyframes tryHandle {
  0%   { transform: rotate(0deg); }
  5%   { transform: rotate(-20deg); }
  20%  { transform: rotate(-5deg); }
  30%  { transform: rotate(-20deg); }
  40%  { transform: rotate(-5deg); }
  50%  { transform: rotate(-5deg); }
  62%  { transform: rotate(-20deg); }
  70%  { transform: rotate(0deg); }
  75%  { transform: rotate(0deg); }
  88%  { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
}
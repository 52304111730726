@font-face {
  font-family: "PIXymbols Digit Clocks W90 Bd";
  src: url("/fonts/35043a149682a862b352fa51c0e25869.eot"); /* IE9*/
  src: url("/fonts/35043a149682a862b352fa51c0e25869.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/35043a149682a862b352fa51c0e25869.woff2") format("woff2"), /* chrome、firefox */
  url("/fonts/35043a149682a862b352fa51c0e25869.woff") format("woff"), /* chrome、firefox */
  url("/fonts/35043a149682a862b352fa51c0e25869.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/fonts/35043a149682a862b352fa51c0e25869.svg#PIXymbols Digit Clocks W90 Bd") format("svg"); /* iOS 4.1- */
}

body {
  background-color: #000;
  /*background-color: #8300FF;*/
  /*background-image: url(/background.jpg);
  background-position: center;
  background-size: 1728px;
  min-height: 900px;*/
}

#root, .App {
  color: #fff;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

#rootFrame {
  width: 1280px;
  height: 720px;
  border: 0;
  overflow: hidden;
  /* border: 1px solid purple; */
}

body, .App {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  /*cursor: url('/cursor.png'), auto;*/
}

img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -moz-user-select: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: none;
  -ms-user-select: none;
}

button:focus,
div:focus,
video:focus {
  outline-style: none;
  box-shadow: none;
}

.ant-modal-close-x {
  font-size: 16px;
}

.ant-modal {
  top: 0;
  width: 100%;
  padding-bottom: 56.25%;
}

.ant-modal-wrap {
  overflow: hidden;
}

.ant-modal-content .ant-modal-header {
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  color: #eee;
  padding: 0;
  height: 7.8125vw;
}

.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 3vw;
  line-height: 7.8125vw;
  height: 7.8125vw;
  z-index: 10;
  padding-right: 7.8125vw;
  padding-left: 7.8125vw;
  color: #eee;
}

.ant-modal-content .ant-modal-body {
  height: 48.6vw;
  margin-top: 4vw;
}

.transparent .ant-modal-content {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}


.game-confirm .ant-modal-confirm-body {
  padding: 20px;
}

.game-confirm .ant-modal-confirm-btns {
  float: none;
  text-align: center;
  padding-bottom: 40px;
}

.game-confirm .ant-modal-confirm-btns button {
  padding: 10px 20px;
  height: auto;
}

.game-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 1.4em;
  text-align: center;
}

.game-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 1.8em;
  text-align: center;
}

.ant-btn-loading::before {
  display: none !important;
}

/* .ant-btn-loading */
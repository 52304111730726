.accessCodeWrap {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.accessCodeWrap .logo {
  position: absolute;
  width: 16vw;
  margin-left: -8vw;
  left: 50vw;
  top: 8vw;
}

.accessCodeWrap h3 {
  color: #fff;
  font-weight: bold;
  font-size: 2vw;
  text-transform: uppercase;
  position: absolute;
  top: 26vw;
  left: 0;
  right: 0;
  text-align: center;
  letter-spacing: 0.3vw;
}


.accessCodeWrap .accessCodeInput {
  position: absolute;
  width: 30vw;
  top: 31vw;
  left: 50vw;
  margin-left: -15vw;
  font-size: 1.5vw;
}

.accessCodeWrap .accessCodeInput input {
  background-color: transparent;
  text-align: center;
  border: 0.2vw solid #fff;
  font-size: 2vw;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
}

.accessCodeWrap .button {
  width: 20vw;
  color: #fff;
  position: absolute;
  top: 37vw;
  left: 50vw;
  margin-left: -10vw;
  font-size: 2vw;
  background-color: transparent !important;
  letter-spacing: 0.1vw;
}

.accessCodeWrap .button:hover {
  color: #DE5196;
  /* color: #741FBB; */
}

.accessCodeWrap .noKey {
  color: #fff;
  text-align: center;
  letter-spacing: 0.15vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 47vw;
  font-size: 1.2vw;
}

.accessCodeWrap .noKey a {
  color: #fff;
}

.accessCodeWrap .noKey a:hover {
  color: #DE5196;
  /* color: #741FBB; */
}

.EPlogo {
  position: absolute;
  width: 3vw;
  margin-left: -1.5vw;
  top: 50vw;
  left: 50vw;
}

.EPlogo img {
  width: 3vw;
}
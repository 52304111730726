.openCabinet,
.openCabinetDone {
  position: absolute;
  top: 0vw;
  right: 0vw;
  width: 100vw;
  transition: opacity 1.5s linear;
  pointer-events: none;
}

.seven {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  animation-name: light1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.lightFire1,
.lightFire2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  animation-name: fire;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.lightFire2 {
  animation-duration: 0.5s;
}

@keyframes light1 {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fire {
  0%   { opacity: 1; }
  33%  { opacity: 1; }
  50%  { opacity: 0; }
  90%  { opacity: 0; }
  100% { opacity: 1; }
}
.screen1,
.screen2 {
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #3f3;
  font-size: 3.4vw;
  position: absolute;
  top: 7.8vw;
  left: 49.5vw;
}

.tray {
  position: absolute;
  width: 20vw;
  top: 31vw;
  left: 72.5vw;
}

.dropzone {
  position: absolute;
}

.dropzoneShelf {
  width: 73vw;
  height: 51vw;
  top: 10vw;
  left: 2vw;
}

.dropzoneWeight {
  width: 25vw;
  height: 41vw;
  top: 10vw;
  left: 75vw;
  z-index: 3;
}

.food {
  background-size: cover;
  display: inline-block;
}

.foodDrag {
  position: absolute;
  cursor: url('/pointer.png'), auto;
  text-align: center;
  z-index: 4;
}

.foodDragPumpkin {
  top: 12.4vw;
  left: 15.2vw;
}

.foodPumpkin {
  height: 10.8vw;
  width: 13vw;
}

.foodDragWatermelon {
  top: 25.2vw;
  left: 16.4vw;
}

.foodWatermelon {
  height: 12.2vw;
  width: 13.3vw;
}

.foodDragCarrot {
  top: 16.1vw;
  left: 31.3vw;
}

.foodCarrot {
  height: 7.8vw;
  width: 7.8vw;
}

.foodDragGrapes {
  top: 28.5vw;
  left: 32.2vw;
}

.foodGrapes {
  height: 8.3vw;
  width: 7.8vw;
}

.foodDragOnion {
  top: 15.8vw;
  left: 43.2vw;
}

.foodOnion {
  height: 7.4vw;
  width: 7.7vw;
}

.foodDragPomegranade {
  top: 27.8vw;
  left: 43.5vw;
}

.foodPomegranade {
  height: 7.7vw;
  width: 7.9vw;
}

.foodDragZucchini {
  top: 17.2vw;
  left: 53.2vw;
}

.foodZucchini {
  height: 5.5vw;
  width: 14.9vw;
}

.foodDragBanana {
  top: 29.3vw;
  left: 54.6vw;
}

.foodBanana {
  height: 6vw;
  width: 14vw;
}

.foodDragPumpkin.foodOnWeight,
.foodDragWatermelon.foodOnWeight {
  top: 23vw;
  left: 75.5vw;
}

.foodDragCarrot.foodOnWeight,
.foodDragGrapes.foodOnWeight {
  top: 28vw;
  left: 78vw;
}

.foodDragOnion.foodOnWeight,
.foodDragPomegranade.foodOnWeight {
  top: 27vw;
  left: 78.5vw;
}

.foodDragBanana.foodOnWeight,
.foodDragZucchini.foodOnWeight {
  top: 29vw;
  left: 75.5vw;
}

.screen {
  position: absolute;
  color: #3f3;
  left: 81.4vw;
  top: 39.6vw;
  height: 2.9vw;
  width: 5.6vw;
}

.screen span {
  position: absolute;
  right: 0.2vw;
  top: 0.9vw;
}

.screen .weight {
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size: 1.8vw;
  position: absolute;
  width: 6vw;
  right: 1vw;
  top: 0.1vw;
  text-align: right;
}
.bacteria {
  position: absolute;
  width: 2.5vw;
}

.knob {
  position: absolute;
  width: 11vw;
  top: 32vw;
  left: 46.8vw;
  transform-origin: 57% 50%;
}

.light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  animation-name: light1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes light1 {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

.reflections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  opacity: 0.5;
}

.labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  opacity: 1;
}
.cutscene {
  position: absolute;
  top: 2vw;
  left: 7vw;
  right: 7vw;
  bottom: 0vw;
  /* border: 1px solid purple; */
}

.continueButton {
  position: absolute;
  left: 39vw;
  top: 49vw;
}
.disc {
  position: absolute;
  top: 0;
  width: 25.59vw;
  cursor: url(/pointer.png), auto;
  transition: transform 500ms ease-in-out;
  border-radius: 11vw;
  overflow: visible;
}

.discTL {
  top: 10.33vw;
  left: 34.85vw;
  z-index: 2;
}

.discTR {
  top: 10.33vw;
  left: 42.9vw;
  z-index: 1;
}

.discB {
  top: 14.3vw;
  left: 38.9vw;
  z-index: 3;
}

.cap {
  position: absolute;
  top: 20.4vw;
  left: 44.95vw;
  width: 19.6vw;
  z-index: 4;
  pointer-events: none;
}


.beaker {
  background-size: cover;
  height: 11.2vw;
  width: 2.1vw;
}

.beakerDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.beakerDragYellow {
  top: 21.9vw;
  left: 62.4vw;
}

.beakerDragPurple {
  top: 20.7vw;
  left: 65.8vw;
}

.beakerDragGreen {
  top: 19.5vw;
  left: 69.05vw;
}

.beakerDragBlue {
  top: 18.2vw;
  left: 72.35vw;
}

.beakerDragGrey {
  top: 23.3vw;
  left: 59.1vw;
}

.beakerDragOrange {
  top: 24.7vw;
  left: 55.7vw;
}

.beakerDragRed {
  top: 17vw;
  left: 75.6vw;
}

.beakerDropzone {
  position: absolute;
  top: 17vw;
  left: 29.3vw;
  width: 24vw;
  height: 24vw;
}

.front {
  z-index: 4;
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: 100vw;
  pointer-events: none;
}

.colorMarker {
  z-index: 7;
  height: 5.4vw;
  width: 8vw;
  top: 24.7vw;
  left: 36.7vw;
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  position: absolute;
  pointer-events: none;
}

.smoke {
  position: absolute;
  left: 35vw;
  top: 9vw;
  width: 11vw;
  transition: all 0.5s;
  opacity: 0;
  transform: translateY(0vw);
  z-index: 10;
}

.solutionNumber {
  position: absolute;
  left: 39.6vw;
  top: 11.4vw;
  width: 4vw;
}

.fire1,
.fire2,
.fire3,
.fire4 {
  position: absolute;
  top: 30.2vw;
  left: 39vw;
  width: 3.1vw;
  animation-name: fire;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  z-index: 6;
}

.fire3 {
  animation-delay: 0.15s;
}

.fire4 {
  animation-delay: 0.3s;
}

.fire2 {
  animation-delay: 0.45s;
}

@keyframes fire {
  0%   { opacity: 1; }
  25%  { opacity: 1; }
  40%  { opacity: 0; }
  96% { opacity: 0; }
  100% { opacity: 1; }
}

.fireLight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 5;
  pointer-events: none;
  animation-name: fireLight;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
}

@keyframes fireLight {
  0%   { opacity: 1; }
  20%  { opacity: 0; }
  40%  { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

.bottleFront {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
}
.container {
  text-align: center;
  position: absolute;
  right: 2vw;
  color: #eee;
  top: 1.5vw;
}

.x {
  font-size: 4vw;
  margin-bottom: -2vw;
  display: block;
}

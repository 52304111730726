
.joystickCover {
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: 100vw;
  pointer-events: none;
  z-index: 1;
}

.joystick1 {
  position: absolute;
  top: 27.1vw;
  left: 33.1vw;
  width: 12vw;
  pointer-events: none;
}

.joystick2 {
  position: absolute;
  top: 29vw;
  left: 49.5vw;
  width: 12vw;
  pointer-events: none;
}

.robotArm,
.robotArmPart {
  position: absolute;
  width: 7.5vw;
  top: 7vw;
  left: 43.8vw;
  transition: all 750ms;
  z-index: 7;
}

.probe {
  position: absolute;
  width: 2.9vw;
  top: 7vw;
  left: 43.8vw;
  transition: all 750ms;
  z-index: 5;
}

.row1,
.row2,
.row3,
.glowLeft,
.glowRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
}

.row1 {
  z-index: 7;
}

.row2 {
  z-index: 2;
}

.row3 {
  z-index: 4;
}

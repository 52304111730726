.finalPaper {
  font-family: 'Sanchez', sans-serif;
  font-size: 1.1vw;
  line-height: 1.2vw;
  text-align: justify;
  color: #000;
}

.finalPaper h1 {
  font-size: 2.6vw;
  margin-bottom: 2vw;
}

.finalPaper p {
  margin-bottom: 0.5em;
}

.parallelLabUncovered {
  position: absolute;
  top: 24vw;
  left: 21.4vw;
  bottom: 1vw;
  width: 23.4vw;
}

.parallelLabUncovered .time {
  font-size: 2vw;
  margin-top: 1vw;
}

.otherCases {
  position: absolute;
  top: 15.2vw;
  left: 49.6vw;
  bottom: 3vw;
  width: 20.2vw;
}

.otherCases .specialOffer {
  font-style: italic;
}

.otherCases .unboxingLink img {
  width: 100%;
  margin-top: 1vw;
}

.social {
  position: absolute;
  top: 15.2vw;
  left: 73.6vw;
  bottom: 3vw;
  width: 18vw;
}

.socialMediaButtons {
  margin-top: 1vw;
  line-height: 1.6vw;
}

.socialMediaButtons a {
  line-height: 1.6vw;
  height: auto;
}

.shareButton {
  font-size: 2vw;
  color: #000;
  padding: 0.5vw 0.8vw;
}

.newsletterHeader {
  margin-top: 3vw;
}

.nameInput {
  margin-top: 1vw;
  font-size: 1.6vw;
  margin-bottom: 0;
  height: auto;
}

.nameInput div {
  height: auto;
  min-height: auto;
}

.nameInput input {
  font-size: 1.6vw;
  border: 0.2vw solid #000;
  padding: 0.4vw 0.6vw;
}

.nameInput input {
  background-color: transparent;
}

.signUp {
  position: relative;
  left: -0.8vw;
  top: 1vw;
  font-size: 1.8vw;
  padding: 0.4vw 0.6vw;
  height: auto;
}

.errorMessage {
  font-size: 1.2vw;
  color: #ff0000;
}
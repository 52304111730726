.classicButton {
  position: absolute;
  border: 0.3vw solid #eee;
  color: #eee;
  border-radius: 0.5vw;
  height: auto;
  padding: 1vw 3vw;
  font-size: 2.5vw;
  background-color: transparent;
  border-width: 0.4vw 0.4vw 0.6vw 0.6vw;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
  cursor: url(/pointer.png), auto;
}

.classicButtonSmall {
  padding: 0.5vw 1vw;
  font-size: 1.6vw;
  border-width: 0.3vw 0.3vw 0.45vw 0.3vw;
}

.classicButton:hover,
.classicButton:focus,
.classicButton:active {
  background-color: transparent;
  border-color: #eee;
  color: #eee;
}

.classicButton:disabled,
.classicButton[disabled],
.classicButton[disabled]:hover,
.classicButton[disabled]:focus,
.classicButton[disabled]:active {
  background-color: transparent;
  border-color: #999;
  color: #999;
}

.classicButton:hover,
.classicButton:active {
  background-color: transparent;
  border-color: rgba(255, 255, 0, 1);
  color: rgba(255, 255, 0, 1);
}

.noBorder {
  border: 0;
}
.mobileScreen {
  width: 14vw;
  height: 23vw;
  position: absolute;
  left: 38.3vw;
  top: 6vw;
  transform: rotate(14deg);
  z-index: 1;
}

.enterPasscode,
.enterNumber {
  position: absolute;
  top: 1.5vw;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.5vw;
}

.enterNumber {
  top: 1vw;
  font-size: 2vw;
  color: #000;
}

.dotOpen,
.dotClosed {
  position: absolute;
  top: 5vw;
  width: 1vw;
}

.dotClosed {
  opacity: 0;
}
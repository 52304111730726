.monitor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.additionalLight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
}

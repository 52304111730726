.frame {
  position: relative;
  width: 90vw;
  position: absolute;
  top: 2vw;
  left: 5vw;
}

.frameTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15vw;
  z-index: 0;
}

.frameBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  height: 15vw;
  z-index: 0;
}

.frameMiddle {
  position: absolute;
  top: 14vw;
  bottom: 12.7vw;
  background-repeat: repeat-y;
  background-size:cover;
  left: 0;
  right: 0;
  z-index: 0;
}

.content {
  min-height: 600px;
  padding: 40px;
  z-index: 2;
  position: relative;
}

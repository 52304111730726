
.wrapper {
  position: relative;
  margin: 0 auto;
  background-color: #000;
  width: 100vw;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  /* border-bottom: 1px solid #333; */
  overflow: hidden;
}

.blur {
  filter: blur(15px);
  /* transition: filter 300ms; */
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 0;
}

.gameBoard {
  z-index: 1;
  /*background-color: #fff;*/
  /*width: 933px;*/
  /*height: 700px;*/
  /* border-bottom: 1px solid red; */
  /* background-color: #171717; */
  /* position: absolute; */
}

.timerContainer {
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 7vw;
  z-index: 5;
  position: relative;
  pointer-events: none;
}

.timer {
  padding-top: 0.18vw;
  font-size: 2.7vw;
  margin-bottom: 0;
}

.timerDanger {
  color: #ff5353;
}

.bombActivated {
  font-size: 0.5em;
  color: #ff5353;
}

.timerSecondLine {
  font-size: 1.7vw;
  height: 1.5em;
}

.paused {
  font-size: 1.7vw;
  margin-top: -0.7vw;
  color: #000;
}

.stats {
  position: absolute;
  right: 0;
  width: 15vw;
  padding-top: 1vw;
  padding-right: 1vw;
  color: rgb(218, 214, 206);
}

.detectiveContainer {
  text-align: center;
  position: relative;
  height: 27vw;
}

.detectiveContainer2 {
  text-align: center;
  position: relative;
  left: 0;
  height: 20vw;
  background-size: contain;
  background-repeat: no-repeat;
}

.detectiveName1,
.detectiveName2 {
  position: absolute;
  top: 13.1vw;
  width: 80%;
  left: 1vw;
  color: #000;
  font-size: 1.4vw;
}

.detectiveName2 {
  left: 2.4vw;
  top: 0.4vw;
}

.playerList1,
.playerList2 {
  height: 10vw;
  width: 71%;
  line-height: 1em;
  position: absolute;
  top: 15vw;
  left: 1.1vw;
  display: flex;
  align-items: center;
}

.playerList2 {
  height: 11vw;
  top: 2.8vw;
  left: 1vw;
  width: 80%;
}

.stats ul {
  padding-left: 0;
  list-style-type: none;
  font-size: 1.4vw;
  width: 100%;
  margin-bottom: 0;
}

.stats ul li {
  line-height: 1vw;
  margin-bottom: 1vw
}

.player {
  position: absolute;
  top: 1vw;
  left: 1vw;
  width: 10.8vw;
}

.hudContainer1 {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 26vw;
  background-position-x: 0.6vw;
  position: relative;
}

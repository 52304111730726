.dropzone {
  position: absolute;
  top: 5vw;
  left: 35vw;
  width: 54vw;
  height: 45vw;
  z-index: 2;
}

.dropzoneOut {
  position: absolute;
  top: 5vw;
  left: 2vw;
  width: 33vw;
  height: 45vw;
  z-index: 2;
}

.sample {
  background-size: cover;
  background-repeat: no-repeat;
  width: 10vw;
  height: 6.6vw;
}

.sampleDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.sampleDragBlack {
  top: 16vw;
  left: 3.7vw;
}

.sampleDragGreen {
  top: 24vw;
  left: 5.7vw;
}

.sampleDragBlue {
  top: 9vw;
  left: 10.7vw;
}

.sampleDragRed {
  top: 30vw;
  left: 19.7vw;
}

.sampleDragPink {
  top: 23vw;
  left: 16.7vw;
}

.sampleDragYellow {
  top: 15.5vw;
  left: 14.5vw;
}

.sampleDragOrange {
  top: 32vw;
  left: 7.7vw;
}

.sampleSmallDrag {
  position: absolute;
  width: 8.3vw;
  top: 34.9vw;
  left: 54.23vw;
  height: 5vw;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.sampleSmall {
  width: 8.1vw;
  height: 2.1vw;
  background-size: cover;
  background-repeat: no-repeat;
}

.virus {
  position: absolute;
  width: 10vw;
  top: 20.2vw;
  left: 68.1vw;
}

.dragTutorial {
  position: absolute;
  top: 31vw;
  left: 30vw;
  width: 15vw;
  transition: opacity 500ms ease-in-out;
}
.openDoor {
  position: absolute;
  width: 100vw;
  left: 0vw;
  top: 0vw;
}

.item {
  background-size: cover;
  display: inline-block;
  height: 8vw;
  width: 5.8vw;
}

.itemDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
  text-align: center;
}

.itemDrag.itemDrag1.itemInSlot0 {
  top: 25.5vw;
  left: 30.5vw;
}

.itemDrag.itemInSlot1 {
  top: 9vw;
  left: 30.1vw;
}

.itemDrag.itemDrag2.itemInSlot0 {
  top: 25.5vw;
  left: 36vw;
}

.itemDrag.itemInSlot2 {
  top: 9vw;
  left: 37.2vw;
}

.itemDrag.itemDrag3.itemInSlot0 {
  top: 25.5vw;
  left: 41.5vw;
}

.itemDrag.itemInSlot3 {
  top: 9vw;
  left: 44.4vw;
}

.itemDrag.itemDrag4.itemInSlot0 {
  top: 25.5vw;
  left: 46.9vw;
}

.itemDrag.itemInSlot4 {
  top: 9vw;
  left: 51.5vw;
}

.itemDrag.itemDrag5.itemInSlot0 {
  top: 25.5vw;
  left: 55.9vw;
}

.itemDrag.itemInSlot5 {
  top: 9vw;
  left: 58.4vw;
}

.itemDrag.itemDrag6.itemInSlot0 {
  top: 25.5vw;
  left: 62.6vw;
}

.itemDrag.itemInSlot6 {
  top: 9vw;
  left: 65.4vw;
}

.dropzone {
  position: absolute;
  z-index: 1;
}

.dropzone0 {
  top: 24vw;
  left: 23vw;
  width: 51vw;
  height: 20vw;
}

.dropzone1 {
  top: 3vw;
  left: 28vw;
  width: 8.5vw;
  height: 16.8vw;
}

.dropzone2 {
  top: 3vw;
  left: 36.5vw;
  width: 7vw;
  height: 16.8vw;
}

.dropzone3 {
  top: 3vw;
  left: 43.5vw;
  width: 7vw;
  height: 16.8vw;
}

.dropzone4 {
  top: 3vw;
  left: 50.5vw;
  width: 7vw;
  height: 16.8vw;
}

.dropzone5 {
  top: 3vw;
  left: 57.5vw;
  width: 7vw;
  height: 16.8vw;
}

.dropzone6 {
  top: 3vw;
  left: 64.5vw;
  width: 8vw;
  height: 16.8vw;
}

.numberLight {
  position: absolute;
  top: 18.1vw;
  left: 42.05vw;
  width: 9.1vw;
}

/* --------------------- */

.scaleLeft,
.scaleRight {
  position: absolute;
  top: 5vw;
  left: 0vw;
  width: 42vw;
}

.dragTutorialLeft,
.dragTutorialRight {
  position: absolute;
  top: 27vw;
  left: 26vw;
  width: 13vw;
}

.dragTutorialRight {
  left: 52vw;
}

.scaleDropLeft,
.scaleDropRight {
  position: absolute;
  top: 5vw;
  left: 37vw;
  width: 42vw;
  height: 45vw;
}

.scaleDropRight {
  left: 12.7vw;
}

.placeholderDropLeft,
.placeholderDropRight {
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: 36vw;
  height: 48vw;
}

.placeholderDropRight {
  left: 59vw;
}

.placeholder {
  position: absolute;
  width: 26vw;
  top: 35vw;
  left: 5vw;
}

.weight {
  background-size: cover;
}

.weight1 {
  height: 8vw;
  width: 4vw;
}

.weight3 {
  height: 11vw;
  width: 5.5vw;
}

.weight9 {
  height: 14vw;
  width: 7.5vw;
}

.weightDragLeft,
.weightDragRight {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.weightDragLeft.weightDrag1 {
  top: 32vw;
  left: 9vw;
}

.weightDragLeft.weightDrag3 {
  top: 30vw;
  left: 14vw;
}

.weightDragLeft.weightDrag9 {
  top: 26vw;
  left: 20vw;
}

.weightDragRight.weightDrag1 {
  top: 33vw;
  left: 67vw;
}

.weightDragRight.weightDrag3 {
  top: 30vw;
  left: 73vw;
}

.weightDragRight.weightDrag9 {
  top: 26vw;
  left: 79vw;
}

.weightDragLeft.weightOnScale.weightDrag1 {
  top: 18vw;
  left: 39vw;
}

.weightDragLeft.weightOnScale.weightDrag3 {
  top: 14.4vw;
  left: 49.2vw;
}

.weightDragLeft.weightOnScale.weightDrag9 {
  top: 11.5vw;
  left: 43vw;
}

.weightDragRight.weightOnScale.weightDrag1 {
  top: 17.8vw;
  left: 38vw;
}

.weightDragRight.weightOnScale.weightDrag3 {
  top: 15.1vw;
  left: 41.6vw;
}

.weightDragRight.weightOnScale.weightDrag9 {
  top: 11.5vw;
  left: 46.4vw;
}

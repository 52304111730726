.vial {
  background-size: cover;
  height: 9.5vw;
  width: 2.7vw;
}

.vialDrag {
  position: absolute;
  z-index: 2;
  cursor: url('/pointer.png'), auto;
}

.vialDrag53 { top: 33.9vw; left: 42vw; }
.vialDrag10 { top: 33.9vw; left: 45.95vw; }
.vialDrag46 { top: 33.9vw; left: 49.96vw; }
.vialDrag13 { top: 33.9vw; left: 53.96vw; }
.vialDrag83 { top: 33.9vw; left: 57.9vw; }
.vialDrag15 { top: 33.9vw; left: 61.85vw; }
.vialDrag60 { top: 33.9vw; left: 65.9vw; }
.vialDrag57 { top: 33.9vw; left: 69.9vw; }
.vialDrag25 { top: 33.9vw; left: 73.85vw; }
.vialDrag75 { top: 33.9vw; left: 77.8vw; }
.vialDrag08 { top: 33.9vw; left: 81.76vw; }
.vialDrag16 { top: 33.9vw; left: 85.71vw; }

.vialDropzonePoland,
.vialDropzoneIreland,
.vialDropzoneNepal {
  position: absolute;
  top: 3vw;
  left: 32vw;
  width: 12vw;
  height: 14vw;
  /* background-color: rgba(255, 0, 0, 0.2);
  border: 1px solid red; */
}

.vialDropzonePoland {
  width: 10.4vw;
  left: 30.7vw;
}

.vialDropzoneIreland {
  left: 41.1vw;
  width: 8vw;
}

.vialDropzoneNepal {
  left: 49.1vw;
  width: 10.4vw;
}

.slotDisplay {
  position: absolute;
  width: 4vw;
  height: 4vw;
  text-align: center;
  font-size: 1.7vw;
  line-height: 3.9vw;
  padding-left: 0.2vw;
  white-space: nowrap;
}

.slotState {
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0.2vw solid #000;
  background-color: rgba(188, 100, 75, 0.9);
  transition: height 0.5s ease-in-out;
}

.slotStateError {
  border-top: 0;
  background-color: rgba(255, 0, 0, 0.8);
}

.slotStateNoBorder {
  border-top: 0;
}

.slotDisplayPoland {
  left: 58.05vw;
  top: 29.15vw;
}

.slotDisplayIreland {
  left: 28.7vw;
  top: 29.15vw;
}

.slotDisplayNepal {
  left: 43.3vw;
  top: 29.15vw;
}

.resetButton {
  position: absolute;
  width: 4.5vw;
}

.resetButtonPoland {
  left: 18.3vw;
  top: 37.9vw;
}

.resetButtonIreland {
  left: 22.9vw;
  top: 37.9vw;
}

.resetButtonNepal {
  left: 27.6vw;
  top: 37.9vw;
}

.tableFront {
  position: absolute;
  left: 0vw;
  top: 0vw;
  width: 100vw;
  pointer-events: none;
  z-index: 3;
}

.symbol {
  position: absolute;
  width: 4vw;
  left: 0vw;
  top: 0vw;
}

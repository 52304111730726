.lights {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  animation-name: light1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes light1 {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}
.painting {
  position: absolute;
  width: 13.8vw;
  top: 0vw;
  left: 0vw;
  transition: all ease-in-out 2.5s;
  /* opacity: 0.3; */
  z-index: 2;
}

.paintingOpen {
  width: 20vw;
  top: -5.5vw;
  left: -18.7vw;
}

.smokeVent {
  position: absolute;
  width: 10vw;
  z-index: 5;
  animation-name: hissing;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.smokeVent1 {
  top: 0vw;
  left: 32vw;
}

.smokeVent1.smokeVentOldDog {
  left: 29vw;
}

.smokeVent2 {
  top: 0vw;
  left: 50vw;
}

.smokeVent2.smokeVentOldDog {
  left: 47vw;
}

.smokeVent3 {
  top: 1vw;
  left: 68vw;
}

.smokeVent3.smokeVentOldDog {
  left: 65vw;
}

@keyframes hissing {
  0%   { opacity: 0;   transform: translateY(0); }
  45%  { opacity: 0.8; transform: translateY(2vw); }
  95%  { opacity: 0;   transform: translateY(4vw); }
  100% { opacity: 0;   transform: translateY(0); }
}

.smoke {
  top: 22vw;
  left: 9vw;
  width: 77vw;
  position: absolute;
  transition: all linear 15s;
  z-index: 6;
  animation-name: smoke;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  pointer-events: none;
}

.lightRed,
.lightFruits,
.lightClock,
.lightFish,
.lightMonitor,
.lightOldDog {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  animation-name: light2;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes light1 {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes light2 {
  0%   { opacity: 1; }
  49.99%  { opacity: 1; }
  50%  { opacity: 0.5; }
  50.5%  { opacity: 0.5; }
  50.51%  { opacity: 1; }

  /* 55.99%  { opacity: 1; }
  56%  { opacity: 0.5; }
  56.5%  { opacity: 0.5; }
  56.51%  { opacity: 1; } */

  79.99%  { opacity: 1; }
  80%  { opacity: 0.5; }
  80.5%  { opacity: 0.5; }
  81%  { opacity: 0.7; }
  81.5%  { opacity: 0.3; }
  82%  { opacity: 0.2; }
  82.51%  { opacity: 1; }

  100% { opacity: 1; }
}

@keyframes smoke {
  0%   { }
  50%  { transform: translate(0, 2vw); }
  100% { }
}
.oldDogShelves {
  position: absolute;
  z-index: 2;
  top: 0vw;
  right: 0vw;
  width: 27.1vw;
  transition: transform ease-in-out 5s;
}

.allyShelves {
  position: absolute;
  z-index: 2;
  top: 0vw;
  right: 0vw;
  width: 34.8vw;
  transition: transform ease-in-out 5s;
}

.oldDogShelvesSolved,
.allyShelvesSolved {
  transform: translateX(22vw);
}

.oldDogLight1 {
  position: absolute;
  z-index: 1;
  right: 3.5vw;
  width: 69.5vw;
  top: 3.5vw;
  animation-name: light1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.allyLight1 {
  position: absolute;
  z-index: 1;
  right: 12.2vw;
  width: 53.9vw;
  top: 0.7vw;
  animation-name: light1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.oldDogLight2 {
  position: absolute;
  z-index: 2;
  bottom: 0vw;
  right: 0vw;
  width: 42vw;
  animation-name: light2;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.allyLightRed {
  position: absolute;
  z-index: 2;
  top: 23.2vw;
  left: 23.5vw;
  width: 27.8vw;
  animation-name: light2;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.oldDogLight3 {
  position: absolute;
  z-index: 2;
  top: 25.1vw;
  left: 18.35vw;
  width: 34.2vw;
  animation-name: light1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.allyLightBlue {
  position: absolute;
  z-index: 2;
  top: 0vw;
  left: 0vw;
  width: 35.2vw;
  animation-name: light1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.oldDogShelvesLight {
  position: absolute;
  z-index: 3;
  top: 27.1vw;
  right: 0vw;
  width: 13.5vw;
  animation-name: light2;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: transform ease-in-out 5s;
}

.oldDogShelvesLightSolved {
  transform: translateX(22vw);
}

@keyframes light1 {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes light2 {
  0%   { opacity: 1; }
  49.99%  { opacity: 1; }
  50%  { opacity: 0.5; }
  50.5%  { opacity: 0.5; }
  50.51%  { opacity: 1; }

  55.99%  { opacity: 1; }
  56%  { opacity: 0.5; }
  56.5%  { opacity: 0.5; }
  56.51%  { opacity: 1; }

  79.99%  { opacity: 1; }
  80%  { opacity: 0.5; }
  80.5%  { opacity: 0.5; }
  80.51%  { opacity: 1; }

  100% { opacity: 1; }
}
.interface {
  position: absolute;
  top: 14.6vw;
  left: 41.8vw;
  width: 16vw;
  height: 25vw;
  /* border: 1px solid purple; */
  /* transform: rotate(-12.7deg); */
}

.screen {
  position: absolute;
  left: 3.2vw;
  top: 1vw;
  height: 4vw;
  right: 2.7vw;
  font-size: 2.2vw;
  padding-top: 1vw;
  white-space: nowrap;
}

.keyboard {
  position: absolute;
  top: 10vw;
  left: 1.8vw;
  width: 13vw;
  height: 14.7vw;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  pointer-events: none;
}

.button {
  position: absolute;
  /* border: 1px solid red; */
  border: 0;
  padding: 0;
  /* background-color: rgba(255, 255, 255, 0.4); */
  transition: unset;
}

.button1 {
  height: 4.5vw;
  width: 3.9vw;
  top: 10.6vw;
  left: 2.2vw;
}

.button2 {
  height: 4.5vw;
  width: 4.2vw;
  top: 10.5vw;
  left: 6.2vw;
}

.button3 {
  height: 4.5vw;
  width: 3.9vw;
  top: 10.6vw;
  left: 10.4vw;
}

.button4 {
  height: 4.6vw;
  width: 4vw;
  top: 15.1vw;
  left: 2.1vw;
}

.button5 {
  height: 4.7vw;
  width: 4.3vw;
  top: 15.1vw;
  left: 6.2vw;
}

.button6 {
  height: 4.6vw;
  width: 4vw;
  top: 15.2vw;
  left: 10.5vw;
}

.button7 {
  height: 4.4vw;
  width: 4vw;
  top: 19.8vw;
  left: 2.3vw;
}

.button8 {
  height: 4.7vw;
  width: 4.2vw;
  top: 19.8vw;
  left: 6.3vw;
}

.button9 {
  height: 4.7vw;
  width: 3.8vw;
  top: 19.8vw;
  left: 10.6vw;
}

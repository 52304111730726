.hints {
  color: #fff;
  font-size: 1.4vw;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  border-radius: 1vw;
  padding: 2vw 1vw;
  position: absolute;
  left: 20vw;
  right: 20vw;
  top: 4vw;
  bottom: 0vw;
}

.hintsButton {
  position: static !important;
  margin: 1vw 0 0;
  font-size: 1.2vw !important;
  border-width: 0.1vw !important;
  border-radius: 0.5vw !important;
}

.steps {
  color: #fff;
  margin-top: 4vw;
  font-size: 1.4vw;
  border-bottom: 0.1vw solid #fff;
}

.stepsButton {
  border: 0;
  background-color: transparent;
  margin: 0.5vw 1vw;
  color: #fff;
  cursor: pointer;
}

.stepsButton:hover,
.stepsButton:focus,
.stepsButton:active {
  background-color: transparent;
  border-color: #eee;
  color: #eee;
}

.stepsButton:hover,
.stepsButton:active {
  background-color: transparent;
  border-color: rgba(255, 255, 0, 1);
  color: rgba(255, 255, 0, 1);
}

.stepsButtonActive,
.stepsButtonActive:active,
.stepsButtonActive:hover,
.stepsButtonActive:focus {
  color: rgba(255, 255, 0, 1);
}

.stepsButtonCompleted {
  text-decoration: line-through;
}

.steps div {
  font-size: 1.4vw;
}

.hintContent {
  padding: 2vw;
}
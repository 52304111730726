.container {
  text-align: center;
  position: absolute;
  right: 0.6vw;
  top: 1.7vw;
  font-weight: normal;
  font-size: 1.2vw;
  z-index: 10;
  width: 6vw;
  font-family: 'Montserrat', sans-serif;
}

.control {
  color: #fff;
  opacity: 0.9;
  width: 6vw;
  cursor: url('/pointer.png'), auto;
  transition: opacity 300ms;
  margin-bottom: 1.4vw;
}

.control:hover {
  opacity: 1;
}

.close {
  display: block;
}

.x {
  font-size: 3vw;
  display: block;
}

.icon {
  width: 2.8vw;
  margin-bottom: 0.3vw;
}

.hintIcon {
  width: 3vw;
}
/*Ripple*/
.ripple {
  width: 5vw;
  height: 5vw;
  opacity: 0;
  transform: scale(0);
  background: rgba(255, 255, 255, 0.8);
  background: transparent;
  border: 0.3vw solid #fff;
  border-radius: 50%;
  margin-left: -2.5vw;
  margin-top: -2.5vw;
  position: fixed;
  animation: ripple-mo 1s cubic-bezier(0, 0, 0.2, 1);
  animation-iteration-count: 1;
  z-index: 1001;
  pointer-events: none;
}

@keyframes ripple-mo {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.closed {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 2;
}

.grid {
  position: absolute;
  width: 22vw;
  height: 21.5vw;
  top: 14.8vw;
  left: 32.3vw;
  z-index: 3;
}

.piece {
  position: absolute;
  cursor: url(/pointer.png), auto;
  transition: top 500ms, left 500ms;
  z-index: 1;
}

.pieceExample {
  width: 3vw;
  height: 3vw;
  top: 39.5vw;
  left: 71.3vw;
  transform: rotate(10deg);
  z-index: 6;
  cursor: auto;
}

.case {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 3;
}

.pipePiece {
  position: absolute;
  top: 13.4vw;
  left: 38.1vw;
  width: 5.76vw;
  cursor: url(/pointer.png), auto;
}

.coverWrapper {
  position: absolute;
  top: 11vw;
  left: 31.5vw;
  width: 38.3vw;
  z-index: 10;
  transform-origin: 1vw 1vw;
}

.cover {
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: 38.3vw;
  cursor: url(/pointer.png), auto;
}

.tremble {
  animation: tremble 1s linear;
  /* transform-origin: center center; */
}

@keyframes tremble {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-6deg);
  }
  20% {
    transform: rotateZ(4deg);
  }
  25% {
    transform: rotateZ(-4deg);
  }
  30% {
    transform: rotateZ(3deg);
  }
  35% {
    transform: rotateZ(-2deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

.coverFall {
  animation: coverFall 4s ease-in-out;
}

.coverInvisible {
  display: none;
}

@keyframes coverFall {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(85deg);
  }
  40% {
    transform: rotate(35deg);
  }
  100% {
    transform: translateY(200vw) rotate(50deg);
  }
}

.gridBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 2;
}

.statusLight {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 3;
}

.lightLabel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 4;
}
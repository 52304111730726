.item {
  width: 20%;
  position: absolute;
  top: 10%;
  left: 10%;
  border: 2px solid transparent;
  border-radius: 20px;
  z-index: 5;
}

.item:hover, .showBorder {
  /*border: 2px dashed #8300FF;*/
  /* border-color: #f38a34; */
  border-color: #000;
  box-shadow: 0 0 15vw rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.05);
  cursor: url('/glass.png'), auto;
  border-width: 0.4vw 0.4vw 0.6vw 0.6vw;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
}

.shortDescription {
  position: absolute;
  top: -3.5vw;
  font-size: 2.1vw;
  color: #eee;
  text-align: center;
  width: calc(100% + 12vw);
  left: -6vw;
  text-shadow: 0 0 0.45vw rgba(0, 0, 0, 0.5);
  display: none;
}

.shortDescriptionBottom {
  bottom: -3.5vw;
  top: auto;
}

.item:hover .shortDescription,
.showBorder .shortDescription {
  display: block;
}
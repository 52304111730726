.button {
  width: 3.9vw;
  text-align: center;
  position: absolute;
}

.button1 {
  left: 54.4vw;
  top: 19.3vw;
}

.button2 {
  left: 58.5vw;
  top: 19.3vw;
}

.button3 {
  left: 62.6vw;
  top: 19.3vw;
}

.button4 {
  left: 54.4vw;
  top: 23.1vw;
}

.button5 {
  left: 58.5vw;
  top: 23.1vw;
}

.button6 {
  left: 62.6vw;
  top: 23.1vw;
}

.button7 {
  left: 54.4vw;
  top: 27.1vw;
}

.button8 {
  left: 58.5vw;
  top: 27.1vw;
}

.button9 {
  left: 62.6vw;
  top: 27.1vw;
}

.buttonOK {
  left: 54.4vw;
  top: 31.2vw;
}

.button0 {
  left: 58.5vw;
  top: 31.2vw;
}

.buttonDEL {
  left: 62.6vw;
  top: 31.2vw;
}

.documents {
  position: absolute;
  top: 14.8vw;
  left: 33.3vw;
  width: 34.6vw;
  cursor: url('/pointer.png'), auto;
  opacity: 1;
}

.handleShadow {
  position: absolute;
  left: 33.5vw;
  top: 5.5vw;
  width: 22vw;
  z-index: 1;
}

.handle {
  position: absolute;
  left: 34vw;
  top: 5vw;
  width: 22vw;
  cursor: url(/pointer.png), auto;
  z-index: 2;
}

.handlemoving {
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-name: tryHandle;
  transform-origin: 3.7vw 3.3vw;
}

@keyframes tryHandle {
  0%   { transform: rotate(0deg); }
  50%  { transform: rotate(30deg); }
  100% { transform: rotate(0deg); }
}

.handleunlocked {
  transition: transform 500ms ease-in-out;
  transform: rotate(90deg);
  transform-origin: 3.7vw 3.3vw;
}

.digits {
  position: absolute;
  left: 53.2vw;
  top: 17.8vw;
  width: 14.2vw;
  z-index: 2;
  pointer-events: none;
}

.screen {
  position: absolute;
  width: 10.4vw;
  height: 3vw;
  top: 13.1vw;
  left: 55.3vw;
  color: rgb(175, 175, 175);
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size: 1.8vw;
  white-space: nowrap;
}

.screendefault,
.screenerror {
  padding-left: 2vw;
  letter-spacing: 0.5vw;
}

.screenparrot,
.screenunlocked {
  color: #3f3;
  font-size: 1.6vw;
  text-align: center;
  line-height: 3vw;
}

.screenerror {
  padding-left: 2vw;
  letter-spacing: 0.5vw;
  animation-name: colorError;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
}

@keyframes colorError {
  0%   { color: rgb(175, 175, 175); }
  25%  { color: rgb(255, 0, 0); }
  50%  { color: rgb(175, 175, 175); }
  75%  { color: rgb(255, 0, 0); }
  100% { color: rgb(175, 175, 175); }
}
